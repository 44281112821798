<template>
  <div>
    <div class="patientRoom mt10">
      <div class="dNOmer mt10 mb-3">
        <el-breadcrumb class="mt-3" separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/hospital' }">{{
            $t("message.hospital")
          }}</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/hospitalPatient' }">{{
            $t("message.patient_histories")
          }}</el-breadcrumb-item>
          <el-breadcrumb-item>{{ patientName }}</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="mt-3">
          <el-button
            v-if="lastHistory.is_ended != 0"
            disabled
            type="danger"
            style="margin-right: 20px; height: 36px"
            @click="end()"
            >{{ $t("message.complated") }}</el-button
          >
          <el-button
            v-else
            type="danger"
            style="margin-right: 20px; height: 36px; vertical-align: middle;"
            @click="end()"
            >{{ $t("message.complated") }}</el-button
          >
          <hospitalCodes
            style="min-width: 300px; vertical-align: middle;"
            :hospital-patient-id="getId"
            :hospital-code="getLastHistoryId"
          />
        </div>
      </div>

      <div class="patientRoom_tabs" v-loading="loadingData">
        <div class="link_blocks d-flex">
          <router-link class="action" :to="{ name: 'patientHome' }">{{
            $t("message.main")
          }}</router-link>
          <router-link class="action" :to="{ name: 'hospitalization' }">{{
            $t("message.hospitalization")
          }}</router-link>
          <router-link :to="{ name: 'patientService' }">{{
            $t("message.services")
          }}</router-link>
          <router-link :to="{ name: 'patientConclusion' }">{{
            $t("message.conclusions")
          }}</router-link>
          <router-link :to="{ name: 'dailyTreatment' }">{{
            $t("message.dailyTreatment")
          }}</router-link>
          <router-link :to="{ name: 'physiotherapy' }">{{
            $t("message.physiotherapy")
          }}</router-link>
          <router-link :to="{ name: 'clientBalance' }">{{
            $t("message.cabinat_balance")
          }}</router-link>
          <router-link :to="{ name: 'finalConclusion' }">{{
            $t("message.final_conclusion")
          }}</router-link>
          <div
            v-if="lastHistory.is_ended != 0"
            class="left_textLastHistory_off"
          >
            {{ $t("message.hospitalization_complated") }}!
            <span
              ><b>{{ $t("message.user") }}:</b>
              {{ lastHistory.endedUser ? lastHistory.endedUser.name : "" }}
              {{ lastHistory.endedUser ? lastHistory.endedUser.surname : "" }}
              {{
                lastHistory.endedUser ? lastHistory.endedUser.patronymic : ""
              }}</span
            >
            <span v-if="lastHistory.reason != null"
              ><b>{{ $t("message.reason") }}:</b>
              {{ lastHistory.reason }}
            </span>
          </div>
          <h4></h4>
          <!-- <router-link :to="{name: '/'}">Операция</router-link> -->
        </div>
        <div class="blocks minH">
          <router-view />
        </div>
      </div>
    </div>
    <el-dialog
      title="Выберите шаблон"
      :append-to-body="true"
      :visible.sync="outerVisible"
      width="40%"
    >
      <el-row :gutter="20">
        <el-col :span="24">
          <el-input
            clearable
            :placeholder="$t('message.search_template')"
            v-model="filterText"
          ></el-input>
          <el-tree
            class="mt-2 filter-tree"
            :data="data"
            node-key="id"
            :filter-node-method="filterNode"
            ref="tree"
            @node-click="handleNodeClick"
            :expand-on-click-node="false"
            show-checkbox
          >
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span class="maxWidht ss">
                {{ data.name }}
              </span>
            </span>
          </el-tree>
        </el-col>
      </el-row>

      <div slot="footer" class="dialog-footer">
        <el-button @click="createConclusion()" type="success">
          {{ $t("message.create") }}
        </el-button>
        <el-button @click="dialogClose()">
          {{ $t("message.close") }}
        </el-button>
      </div>
    </el-dialog>
    <el-dialog
      :append-to-body="true"
      :title="$t('message.reason')"
      :visible.sync="dialogReason"
      width="30%"
    >
      <el-input type="textarea" :rows="2" v-model="reason" />
      <span slot="footer" class="dialog-footer text-center w-100 d-block">
        <el-button @click="cancelOperation(dialogReason)">{{
          $t("message.cancel")
        }}</el-button>
        <el-button
          type="primary"
          style="width: 200px"
          @click="endHosp(getLastHistoryId)"
          >{{ $t("message.yes") }}</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import hospitalCodes from "@/components/selects/hospitalCode";
import { i18n } from "@/utils/i18n";

export default {
  mixins: [drawer],
  name: "patientRooms",
  components: {
    hospitalCodes,
  },
  data() {
    return {
      loadingData: false,
      outerVisible: false,
      filterText: "",
      backUrl: process.env.VUE_APP_URL_DOCS,
      data: null,
      reason: "",
      template_id: null,
      selectedModel: {},
      dialogReason: false,
      drawer: {
        create: {
          name: "create",
          status: false,
          component: "componentDrawerCreate",
        },
      },
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  computed: {
    ...mapGetters({
      lastHistory: "patientHistories/model",
      model: "patients/model",
      patientName: "patients/patientName",
      finalConclusion: "hospitalizationFinalConclusions/list",
      token: "auth/token",
      columns: "patients/columns",
      pagination: "patients/pagination",
      filter: "patients/filter",
      sort: "patients/sort",
    }),
    actions: function () {
      return ["edit", "delete"];
    },
    getId() {
      return this.$route.params.id;
    },
    getLastHistoryId() {
      if (this.lastHistory) {
        return this.lastHistory.id;
      }
      return null;
    },
  },

  beforeRouteLeave(to, from, next) {
    this.$store.commit("dailyTreatments/EMPTY_CODE", "");
    next();
  },
  beforeDestroy() {
    this.$store.commit("patients/SET_CODE", "");
  },
  mounted() {
    if (_.isArray(this.lastHistory) && this.lastHistory.length == 0) {
      this.getLastHistory({ id: this.$route.params.id });
    }
    if (!this.lastHistory) {
      this.getLastHistory({ id: this.$route.params.id });
    }
    if (!this.finalConclusion) {
      this.getFinalConclusion({ id: this.lastHistory.id });
    }
  },
  // beforeRouteLeave(to, from, next) {
  //     this.empty();
  //     next();
  // },
  methods: {
    ...mapActions({
      categoryDocumentationList: "categoryDocumentation/index",
      updateList: "patients/index",
      updateSort: "patients/updateSort",
      updateFilter: "patients/updateFilter",
      updateColumn: "patients/updateColumn",
      getFinalConclusion: "hospitalizationFinalConclusions/index",
      updatePagination: "patients/updatePagination",
      editModel: "patients/show",
      endHospitalization: "patientHistories/endHospitalization",
      empty: "patients/empty",
      delete: "patients/destroy",
      refreshData: "patients/refreshData",
      getLastHistory: "patientHistories/show",
      selectedNumber: "dailyTreatments/getSelectedNumber",
    }),
    end() {
      this.$confirm(
        i18n.t("message.do_you_really_want_to_finish"),
        i18n.t("message.warning"),
        {
          confirmButtonText: i18n.t("message.yes"),
          cancelButtonText: i18n.t("message.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          if (this.finalConclusion) {
            this.endHospitalizationReason();
          } else {
            this.$confirm(
              i18n.t(
                "message.do_you_really_want_to_complete_the_hospitalization"
              ),
              i18n.t("message.warning"),
              {
                confirmButtonText: i18n.t("message.yes"),
                cancelButtonText: i18n.t("message.write_conclusions"),
                type: "warning",
              }
            )
              .then(() => {
                this.endHospitalizationReason();
              })
              .catch((err) => {
                this.categoryDocumentationList({ relation: true })
                  .then((res) => {
                    this.data = res.data.category_forms;
                    this.outerVisible = true;
                  })
                  .catch((err) => {
                    this.outerVisible = true;
                  });
              });
          }
        })
        .catch(() => {
          this.cancelOperation();
        });
    },
    endHospitalizationReason() {
      this.reason = "";
      this.dialogReason = true;
    },
    endHosp(id) {
      if (!this.loadingData) {
        this.loadingData = true;
        const hId = { hospitalization_id: id, reason: this.reason };
        this.endHospitalization(hId)
          .then((res) => {
            this.reason = "";
            this.dialogReason = false;
            const payload = { history_id: this.lastHistory.id };
            this.selectedNumber(payload);
            this.loadingData = false;
          })
          .catch((err) => {
            this.reason = "";
            this.dialogReason = false;
            this.loadingData = false;
          });
      }
    },
    cancelOperation() {
      this.dialogReason = false;
      this.$message({
        type: "warning",
        message: "Операция отменена",
      });
    },
    handleNodeClick(node, data, value) {},
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    dialogClose() {
      this.$refs.tree.setCheckedNodes([]);
      this.outerVisible = false;
    },
    createConclusion() {
      let item = this.$refs.tree.getCheckedNodes();
      let template_id = null;
      for (const i in item) {
        if (item[i]) {
          const element = item[i];
          if (element.children.length == 0 && element.category_id) {
            template_id = element.id;
          }
        }
      }

      this.createModal(template_id);
    },
    async createModal(template_id) {
      var link = document.createElement("a");
      link.setAttribute(
        "href",
        this.backUrl +
          "/file_orientation_patient_room_final?template_id=" +
          template_id +
          "&user=" +
          this.token +
          "&status=create" +
          "&patient_history_id=" +
          this.lastHistory.id
      );
      link.setAttribute("target", "_blank");
      link.click();
      this.dialogClose();
      return;
    },
    fetchData() {
      const query = { ...this.filter, ...this.pagination, ...this.sort };
      if (!this.loadingData) {
        this.loadingData = true;
        this.updateList(query)
          .then((res) => {
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
          });
      }
    },
    refresh() {
      this.refreshData()
        .then((res) => {
          this.filterForm = JSON.parse(JSON.stringify(this.filter));
        })
        .catch((err) => {});
    },
    async show(id) {
      this.$router.push("/patientRoom/main/" + id);
    },
    edit(model) {
      this.selectedModel = model;
      this.drawer.update.status = true;
    },
    destroy(model) {
      this.delete(model.id)
        .then((res) => {
          this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
          this.fetchData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    emptyModel() {
      this.empty();
    },
  },
};
</script>

<style lang="scss" >
.patientRoom_tabs {
  background-color: #fff;
  border-radius: 5px;
}
.link_blocks {
  border-bottom: 1px solid #c0c4cc;
}
.link_blocks a {
  padding: 10px;
  display: inline-block;
  margin-bottom: -1px;
  color: #303030;
}

.link_blocks a:hover {
  text-decoration: none;
}
.link_blocks .router-link-exact-active.router-link-active {
  border-bottom: 1px solid #007bff;
  color: #007bff;
}
.minH {
  min-height: 800px;
}
.dNOmer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.left_textLastHistory_off {
  padding-top: 7px;
  text-align: right;
  flex: 1;
  padding-right: 10px;
  color: red;
  font-size: 16px;
  span {
    margin-left: 20px;
    color: #717171;
  }
}
</style>